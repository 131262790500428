import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PAGE } from "../app.const";
import { ProtectedRoutes } from "../../auth/component/ProtectedLayout";
import { CalibrationPage } from "../../page/CalibrationPage";
import { CalibrationSelectedPage } from "../../page/CalibrationSelectedPage";
import { HomePage } from "../../page/HomePage";
import { LoginPage } from "../../page/LoginPage";
import { NotFoundPage } from "../../page/NotFoundPage";
import { LocationPage } from "../../page/LocationPage";
import { SensorPage } from "../../page/SensorPage";

const router = createBrowserRouter([
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: PAGE.HOME,
        element: <HomePage />,
      },
      {
        path: PAGE.CALIBRATION,
        element: <CalibrationPage />,
      },
      {
        path: `${PAGE.CALIBRATION}/:id`,
        element: <CalibrationSelectedPage />,
      },
      {
        path: PAGE.LOCATION,
        element: <LocationPage />,
      },
      {
        path: PAGE.SENSOR,
        element: <SensorPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export const App = () => {
  return (
    <div className="min-h-screen text-slate-900 bg-slate-50">
      <RouterProvider router={router} />
    </div>
  );
};
