import { axiosX } from "../../lib/axios";
import { useAppDispatch } from "../../store/storeHooks";
import { reloadFetchedLocations } from "../location.slice";

export const useDeleteLocation = () => {
  const dispatch = useAppDispatch();

  const deleteLocation = async (locationId: number) => {
    try {
      await axiosX.delete(`/location/${locationId}`);

      return true;
    } catch (error) {
      console.log(error);

      return false;
    } finally {
      dispatch(reloadFetchedLocations());
    }
  };

  return { deleteLocation };
};
