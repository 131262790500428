import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE } from "../app/app.const";
import { Container } from "../common/component/Container";
import { Link } from "../common/component/Link";
import { Title } from "../common/component/Typography";
import { ChartIntervalSelector } from "../sensor/components/ChartIntervalSelector";
import { LatestSensorView } from "../sensor/components/LatestSensorView";
import { PastSensorDeviceSelector } from "../sensor/components/PastSensorSelector";
import { PastSensorView } from "../sensor/components/PastSensorView";
import { useAppSelector } from "../store/storeHooks";

export const SensorPage = () => {
  const { selectedLocation } = useAppSelector((state) => state.location);
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedLocation) {
      navigate(PAGE.LOCATION);
    }
  }, [selectedLocation, navigate]);

  return (
    <Container.Page>
      <Title.H1>積雪深センサービューワー</Title.H1>
      <Title.Sub>センサー情報詳細</Title.Sub>
      <div className="my-4">
        <Link.Page className="text-base" to={`${PAGE.LOCATION}`}>
          戻る
        </Link.Page>
      </div>

      <Container.Section>
        <Title.H2>
          <span className="font-bold">{selectedLocation?.name}</span>{" "}
          の情報を表示中
        </Title.H2>

        <div className="mt-8">
          <LatestSensorView />
        </div>
      </Container.Section>
      <Container.Section>
        <Title.H2>過去データ</Title.H2>

        <div className="flex gap-4 my-4">
          <PastSensorDeviceSelector />

          <ChartIntervalSelector />
        </div>

        <PastSensorView />
      </Container.Section>
    </Container.Page>
  );
};
