import { ButtonHTMLAttributes, FC } from "react";

/* Button ----------------------------------- */
const Normal: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <button
      className="px-4 py-2 bg-slate-700 hover:bg-slate-500 active:bg-slate-900 text-slate-50 disabled:hover:bg-slate-700 disabled:opacity-30 rounded-md"
      {...props}
    >
      {children}
    </button>
  );
};

const Trans: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <button
      className="px-4 py-2 underline hover:no-underline active:text-slate-500 text-slate-900 disabled:underline disabled:opacity-30 rounded-md"
      {...props}
    >
      {children}
    </button>
  );
};

const Cancel: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  return (
    <button
      className="px-4 py-2 bg-red-700 hover:bg-red-500 active:bg-red-900 text-slate-50 disabled:opacity-30 disabled:hover:bg-red-700 rounded-md"
      {...props}
    >
      {children}
    </button>
  );
};

export const Button = { Normal, Trans, Cancel };
