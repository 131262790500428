import { useContext } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { LoadingText } from "../../common/component/Other";

export const ProtectedRoutes = () => {
  const { currentAuth } = useContext(AuthContext);
  const outlet = useOutlet();

  if (currentAuth === undefined) {
    return <LoadingText />;
  }

  if (!currentAuth) {
    return <Navigate to="/login" />;
  }

  return outlet;
};
