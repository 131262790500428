import { FC, ReactNode } from "react";
import { LoadingText, NoDataText } from "../../common/component/Other";
import { useAppSelector } from "../../store/storeHooks";
import { useSensorLatest } from "../hook/useSensorLatest";
import { SensorService } from "../sensor.service";

const Dl: FC<{ children: ReactNode }> = ({ children }) => (
  <dl className="flex my-2">{children}</dl>
);

const Dt: FC<{ children: ReactNode }> = ({ children }) => (
  <dt className="mr-8 w-32 font-bold">{children}</dt>
);

const Dd: FC<{ children: ReactNode }> = ({ children }) => (
  <dd className="text-base">{children}</dd>
);

export const LatestSensorView = () => {
  const { selectedUnit } = useAppSelector((state) => state.sensor);
  const { sensor } = useSensorLatest(selectedUnit);

  if (sensor === undefined) {
    return <LoadingText />;
  }

  if (sensor === null) {
    return <NoDataText />;
  }

  const dateUpdated = SensorService.extractCreatedAt(sensor);
  const amtSnow = SensorService.extractAmtSnow(sensor);
  const temp = SensorService.extractTemp(sensor);

  return (
    <div>
      <Dl>
        <Dt>最終更新日時</Dt>
        <Dd>{dateUpdated}</Dd>
      </Dl>
      <Dl>
        <Dt>積雪深</Dt>
        <Dd>{amtSnow} cm</Dd>
      </Dl>
      <Dl>
        <Dt>気温</Dt>
        <Dd>{temp} ℃</Dd>
      </Dl>
    </div>
  );
};
