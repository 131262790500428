import { createContext, ReactNode } from "react";
import { AuthTypes } from "./auth.type";
import { useAuth } from "./useAuth";

/**
 * Context
 */
type AuthContextType = {
  currentAuth: AuthTypes | null | undefined;
  update: () => void;
  logout: () => Promise<boolean>;
  login: (loginId: string, password: string) => Promise<boolean>;
};

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = (props: { children: ReactNode }) => {
  const { currentAuth, update, logout, login } = useAuth();

  return (
    <AuthContext.Provider value={{ currentAuth, update, logout, login }}>
      {props.children}
    </AuthContext.Provider>
  );
};
