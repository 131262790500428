import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalSensorTypes } from "./cal-sensor.type";

type CalSensorState = {
  fetchReloader: number;
  fetchedCalSensors: CalSensorTypes[];
};

const initialState: CalSensorState = {
  fetchReloader: 1,
  fetchedCalSensors: [],
};

const calSensorSlice = createSlice({
  name: "calSensor",
  initialState: initialState,
  reducers: {
    reloadFetchedCalSensor(state) {
      state.fetchReloader = state.fetchReloader + 1;
    },
    setFetchedCalSensor(state, action: PayloadAction<CalSensorTypes[]>) {
      state.fetchedCalSensors = action.payload;
    },
  },
});

export const calSensorReducer = calSensorSlice.reducer;
export const { reloadFetchedCalSensor, setFetchedCalSensor } =
  calSensorSlice.actions;
