import { axiosX } from "../../lib/axios";
import { useAppDispatch } from "../../store/storeHooks";
import { reloadFetchedLocations } from "../location.slice";
import { LocationTypes } from "../location.type";

type CreateLocationTypes = Pick<
  LocationTypes,
  "unit" | "name" | "latitude" | "longitude"
>;

export const useCreateLocation = () => {
  const dispatch = useAppDispatch();

  const createLocation = async (location: CreateLocationTypes) => {
    try {
      const postData = {
        unit: location.unit,
        name: location.name,
        latitude: location.latitude,
        longitude: location.longitude,
      };

      await axiosX.post(`/location`, postData);

      return true;
    } catch (error) {
      console.log(error);

      return false;
    } finally {
      dispatch(reloadFetchedLocations());
    }
  };

  return { createLocation };
};
