import { ChangeEvent } from "react";
import { useAppDispatch } from "../../store/storeHooks";
import { SensorDeviceTypes, SENSOR_DEVICE } from "../sensor.const";
import { changeSensorDevice } from "../sensor.slice";

export const PastSensorDeviceSelector = () => {
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newDevice = e.target.value as SensorDeviceTypes;

    dispatch(changeSensorDevice(newDevice));
  };

  return (
    <div>
      <select onChange={handleChange}>
        <option value={SENSOR_DEVICE.AMT_SNOW}>積雪深</option>
        <option value={SENSOR_DEVICE.TEMP}>気温</option>
      </select>
    </div>
  );
};
