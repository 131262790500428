import { Container } from "../common/component/Container";
import { MenuView } from "../common/component/MenuView";
import { Title } from "../common/component/Typography";
import { LocationCsvOutput } from "../location/component/LocationCsvOutput";
import { SensorCsvOutput } from "../sensor/components/SensorCsvOutput";
import { UserInfo } from "../user/component/UserInfo";

export const HomePage = () => {
  return (
    <Container.Page>
      <Title.H1>積雪深センサービューワー</Title.H1>

      <div className="my-4">
        <MenuView />
      </div>

      <Container.Section>
        <Title.H2>CSV出力</Title.H2>

        <div className="mt-4">
          <Title.H3>センサー情報</Title.H3>
          <div className="mt-4">
            <SensorCsvOutput />
          </div>
        </div>

        <div className="mt-8">
          <Title.H3>観測地点情報</Title.H3>
          <div className="mt-4">
            <LocationCsvOutput />
          </div>
        </div>
      </Container.Section>

      <Container.Section>
        <Title.H2>ログイン情報</Title.H2>
        <div className="mt-4">
          <UserInfo />
        </div>
      </Container.Section>
    </Container.Page>
  );
};
