import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE } from "../app/app.const";
import { AuthContext } from "../auth/AuthContext";
import { Button } from "../common/component/Button";
import { Container } from "../common/component/Container";
import { Input } from "../common/component/Input";
import { Text, Title } from "../common/component/Typography";

export const LoginPage = () => {
  const [loginId, setLoginId] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { currentAuth, login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsSending(true);
    setMessage("ログイン中...");

    try {
      const result = await login(loginId, loginPass);

      if (result) {
        setMessage("ログイン成功");
      } else {
        setMessage("ログインに失敗しました");
      }
    } catch (error) {
      console.log(error);

      setMessage(
        "予期せぬエラーが発生しました。時間をおいて再度お試しください"
      );
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    if (currentAuth) {
      navigate(PAGE.LOCATION);
    }
  }, [currentAuth, navigate]);

  return (
    <Container.Page>
      <Title.H1>ログイン</Title.H1>
      <Title.Sub>積雪深センサービューワー</Title.Sub>

      <form className="my-8" onSubmit={handleSubmit}>
        <Input.Wrapper>
          <Input.Label htmlFor="loginId">ユーザー</Input.Label>
          <Input.Field
            type="text"
            id="loginId"
            disabled={isSending}
            required
            value={loginId}
            onChange={(e) => setLoginId(e.target.value)}
          />
        </Input.Wrapper>

        <Input.Wrapper>
          <Input.Label htmlFor="loginPass">パスワード</Input.Label>
          <Input.Field
            type="password"
            id="loginPass"
            disabled={isSending}
            required
            value={loginPass}
            onChange={(e) => setLoginPass(e.target.value)}
          />
        </Input.Wrapper>

        <div className="my-8">
          <Button.Normal type="submit" disabled={isSending}>
            送信
          </Button.Normal>
        </div>

        <div className="my-2">
          <Text.Message>{message}</Text.Message>
        </div>
      </form>
    </Container.Page>
  );
};
