import { formatF } from "../lib/date";
import { SensorTypes } from "./sensor.type";

export class SensorService {
  /**
   *
   */
  public static extractAmtSnow(sensor: SensorTypes | undefined | null): string {
    if (sensor === undefined) return "Loading";
    if (sensor === null) return "NoData";

    return sensor.amtSnow !== null
      ? (sensor.amtSnow / 10).toFixed(1)
      : sensor.amtSnowSt;
  }

  /**
   *
   */
  public static extractTemp(sensor: SensorTypes | undefined | null): string {
    if (sensor === undefined) return "Loading";
    if (sensor === null) return "NoData";

    return sensor.temp !== null ? sensor.temp.toFixed(1) : sensor.tempSt;
  }

  /**
   *
   */
  public static extractCreatedAt(
    sensor: SensorTypes | undefined | null
  ): string {
    if (sensor === undefined) return "Loading";
    if (sensor === null) return "NoData";

    return formatF(sensor.createdAtJst, "yyyy/MM/dd HH:mm:ss");
  }
}
