import { axiosX } from "../../lib/axios";
import { useAppDispatch } from "../../store/storeHooks";
import { LocationStatusTypes, LOCATION_STATUS } from "../location.const";
import { reloadFetchedLocations } from "../location.slice";
import { LocationTypes } from "../location.type";

/**
 *
 */
const useUpdateLocation = () => {
  const dispatch = useAppDispatch();

  const updateLocation = async (
    locationId: number,
    updateData: Partial<LocationTypes>
  ) => {
    try {
      await axiosX.patch(`/location/${locationId}`, updateData);

      return true;
    } catch (error) {
      console.log(error);

      return false;
    } finally {
      dispatch(reloadFetchedLocations());
    }
  };

  return { updateLocation };
};

/**
 *
 */
export const useUpdateLocationInfo = () => {
  const { updateLocation } = useUpdateLocation();

  const updateLocationInfo = async (
    location: Pick<
      LocationTypes,
      "locationId" | "unit" | "name" | "latitude" | "longitude"
    >
  ) => {
    return updateLocation(location.locationId, location);
  };

  return { updateLocationInfo };
};

/**
 *
 */
export const useUpdateLocationStatus = () => {
  const { updateLocation } = useUpdateLocation();

  const updateLocationStatus = async (
    locationId: number,
    targetStatus: LocationStatusTypes
  ): Promise<boolean> => {
    const result = await updateLocation(locationId, { status: targetStatus });

    return result;
  };

  return { updateLocationStatus };
};

/**
 *
 */
export const useFinishLocationCalibration = () => {
  const { updateLocation } = useUpdateLocation();

  const finishLocationCalibration = async (
    locationId: number,
    snowBase: number,
    snowCoef: number
  ): Promise<boolean> => {
    const result = await updateLocation(locationId, {
      status: LOCATION_STATUS.ACTIVE,
      snowBase: snowBase,
      snowCoef: snowCoef,
    });

    return result;
  };

  return { finishLocationCalibration };
};
