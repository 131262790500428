import { useEffect } from "react";
import { Container } from "../common/component/Container";
import { MenuView } from "../common/component/MenuView";
import { Text, Title } from "../common/component/Typography";
import { useQuery } from "../lib/router";
import { CalibrationList } from "../location/component/CalibrationList";
import { CalibrationReserveView } from "../location/component/CalibrationReserveView";
import { useFetchAndStoreLocations } from "../location/hook/useFetchAndStoreLocation";
import { reloadFetchedLocations } from "../location/location.slice";
import { useAppDispatch } from "../store/storeHooks";

const UPDATE_INTERVAL = 5000;

export const CalibrationPage = () => {
  const query = useQuery();
  const queryResult = query.get("result");

  const dispatch = useAppDispatch();

  useFetchAndStoreLocations();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(reloadFetchedLocations());
    }, UPDATE_INTERVAL);

    return () => clearInterval(timer);
  }, [dispatch]);

  return (
    <Container.Page>
      <Title.H1>積雪深センサービューワー</Title.H1>
      <div className="my-4">
        <MenuView />
      </div>

      {queryResult && (
        <div className="my-4">
          <Text.Message>キャリブレーションが完了しました。</Text.Message>
        </div>
      )}

      <Container.Section>
        <Title.H2>データ校正を予約</Title.H2>

        <CalibrationReserveView />
      </Container.Section>

      <Container.Section>
        <Title.H2>校正予約一覧</Title.H2>

        <div className="my-4 max-w-full">
          <CalibrationList />
        </div>
      </Container.Section>
    </Container.Page>
  );
};
