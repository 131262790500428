import { MathEx } from "../lib/math";
import { LocationTypes } from "./location.type";

export class LocationService {
  /**
   *
   */
  public static extractSnowCoef(
    location: LocationTypes | undefined | null
  ): string {
    if (location === undefined) return "Loading";
    if (location === null) return "NoData";

    const longitudeFixed = MathEx.roundDecimal(location.snowCoef, 4);

    return longitudeFixed.toString();
  }

  /**
   *
   */
  public static extractLongitude(
    location: LocationTypes | undefined | null
  ): string {
    if (location === undefined) return "Loading";
    if (location === null) return "NoData";

    const longitudeFixed = MathEx.roundDecimal(location.longitude, 6);

    return longitudeFixed.toString();
  }

  /**
   *
   */
  public static extractLatitude(
    location: LocationTypes | undefined | null
  ): string {
    if (location === undefined) return "Loading";
    if (location === null) return "NoData";

    const latitudeFixed = MathEx.roundDecimal(location.latitude, 6);

    return latitudeFixed.toString();
  }

  /**
   *
   */
  public static extractMapLink(location: LocationTypes): string {
    const mapLink = `https://www.google.co.jp/maps/search/${location.latitude},${location.longitude}/@${location.latitude},${location.longitude},15z`;

    return mapLink;
  }
}
