import { format } from "date-fns";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE } from "../../app/app.const";
import { Table } from "../../common/component/Table";
import { Button } from "../../common/component/Button";
import { Container } from "../../common/component/Container";
import { useAppSelector } from "../../store/storeHooks";
import { useUpdateLocationStatus } from "../hook/useUpdateLocation";
import { LOCATION_STATUS } from "../location.const";
import { LocationTypes } from "../location.type";
import { LocationService } from "../location.service";

const CalibrationHeaderItem = () => (
  <Table.Tr>
    <Table.Td className="font-bold">地点</Table.Td>
    <Table.Td className="font-bold">機器ID</Table.Td>
    <Table.Td className="font-bold">積雪深基準値</Table.Td>
    <Table.Td className="font-bold">積雪深係数</Table.Td>
    <Table.Td className="font-bold">状況</Table.Td>
    <Table.Td className="font-bold">操作</Table.Td>
  </Table.Tr>
);

const CalibrationItem: FC<{
  location: LocationTypes;
}> = ({ location }) => {
  const { updateLocationStatus } = useUpdateLocationStatus();
  const navigate = useNavigate();

  const handleCalibration = () => {
    navigate(`${PAGE.CALIBRATION}/${location.locationId}`);
  };

  const handleCancel = () => {
    updateLocationStatus(location.locationId, LOCATION_STATUS.ACTIVE);
  };

  const statusText = (() => {
    switch (location.status) {
      case LOCATION_STATUS.CAL_READY:
        return `受信待ち（${format(
          new Date(location.updatedAt),
          "M/d HH:mm"
        )}予約）`;
      case LOCATION_STATUS.CAL_SETTING_BASE:
        return "校正中（基準値測定）";
      case LOCATION_STATUS.CAL_SETTING_TARGET:
        return "校正中（校正実行前）";
    }
  })();

  const snowCoef = LocationService.extractSnowCoef(location);

  const disabledCalibration =
    location.status === LOCATION_STATUS.ACTIVE ||
    location.status === LOCATION_STATUS.CAL_READY;

  return (
    <Table.Tr>
      <Table.Td>{location.name}</Table.Td>
      <Table.Td>{location.unit}</Table.Td>
      <Table.Td>{location.snowBase}</Table.Td>
      <Table.Td>{snowCoef}</Table.Td>
      <Table.Td>{statusText}</Table.Td>
      <Table.Td>
        <div className="flex gap-x-2">
          <div>
            <Button.Normal
              disabled={disabledCalibration}
              onClick={handleCalibration}
            >
              校正
            </Button.Normal>
          </div>
          <div>
            <Button.Cancel onClick={handleCancel}>取消</Button.Cancel>
          </div>
        </div>
      </Table.Td>
    </Table.Tr>
  );
};

/**
 *
 */
export const CalibrationList = () => {
  const { fetchedLocations } = useAppSelector((state) => state.location);

  // キャリブレーション中の一覧を取得する
  const calibratingLocations = fetchedLocations
    ? fetchedLocations.filter(
        (l) =>
          l.status === LOCATION_STATUS.CAL_READY ||
          l.status === LOCATION_STATUS.CAL_SETTING_BASE ||
          l.status === LOCATION_STATUS.CAL_SETTING_TARGET
      )
    : [];

  return (
    <Container.ScrollX>
      <table>
        <thead>
          <CalibrationHeaderItem />
        </thead>
        <tbody>
          {calibratingLocations.map((l) => (
            <CalibrationItem key={l.locationId} location={l} />
          ))}
        </tbody>
      </table>
    </Container.ScrollX>
  );
};
