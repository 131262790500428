import { useEffect, useState } from "react";
import { axiosX } from "../../lib/axios";
import { SensorGetLatestTypes, SensorTypes } from "../sensor.type";

export const useSensorLatest = (unit: string) => {
  const [sensor, setSensor] = useState<SensorTypes | null>();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axiosX.get<SensorGetLatestTypes>(
          "/sensor/latest",
          {
            params: {
              unit: unit,
            },
          }
        );

        if (data) {
          const fetchedSensor: SensorTypes = {
            ...data,
            createdAtJst: new Date(data.createdAtJst),
          };

          setSensor(fetchedSensor);
        } else {
          setSensor(null);
        }
      } catch (err) {
        console.error(err);

        setSensor(null);
      }
    };

    fetch();
  }, [unit]);

  return { sensor };
};
