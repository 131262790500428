export const SENSOR_STATUS = {
  OK: "OK",
  E1: "E1",
  E2: "E2",
  NONE: "NONE",
} as const;

export type SensorStatusTypes =
  typeof SENSOR_STATUS[keyof typeof SENSOR_STATUS];

export const SENSOR_DEVICE = {
  AMT_SNOW: "amt_snow",
  TEMP: "temp",
} as const;

export type SensorDeviceTypes =
  typeof SENSOR_DEVICE[keyof typeof SENSOR_DEVICE];

export const SENSOR_CHART_INTERVAL = {
  CONTINUE: "continue",
  HOUR: "hour",
  TWO_HOURS: "twoHours",
  DAY: "day",
  WEEK: "week",
  TWO_WEEKS: "twoWeeks",
  MONTH: "month",
} as const;

export type SensorChartIntervalTypes =
  typeof SENSOR_CHART_INTERVAL[keyof typeof SENSOR_CHART_INTERVAL];
