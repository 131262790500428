import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { PAGE } from "../../app/app.const";
import { Button } from "../../common/component/Button";
import { Container } from "../../common/component/Container";
import { Link } from "../../common/component/Link";
import { LoadingText } from "../../common/component/Other";
import { Table } from "../../common/component/Table";
import { useDeleteLocation } from "../../location/hook/useDeleteLocation";
import { useSensorLatest } from "../../sensor/hook/useSensorLatest";
import { SensorService } from "../../sensor/sensor.service";
import { selectedUnit } from "../../sensor/sensor.slice";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import { LocationService } from "../location.service";
import { setEditingLocation, setSelectedLocation } from "../location.slice";
import { LocationTypes } from "../location.type";

const LocationHeaderItem = () => (
  <Table.Tr>
    <Table.Td className="font-bold">地点名</Table.Td>
    <Table.Td className="font-bold">積雪深(cm)</Table.Td>
    <Table.Td className="font-bold">気温(℃)</Table.Td>
    <Table.Td className="font-bold">最終更新日時</Table.Td>
    <Table.Td className="font-bold">地図</Table.Td>
    <Table.Td className="font-bold">経度</Table.Td>
    <Table.Td className="font-bold">緯度</Table.Td>
    <Table.Td className="font-bold">機器ID</Table.Td>
    <Table.Td className="font-bold">基準値</Table.Td>
    <Table.Td className="font-bold">係数</Table.Td>
    <Table.Td className="font-bold">操作</Table.Td>
  </Table.Tr>
);

/**
 *
 */
const LocationItem: FC<{
  location: LocationTypes;
  onClickMove: (location: LocationTypes) => void;
  onClickEdit: (location: LocationTypes) => void;
  onClickRemove: (location: LocationTypes) => void;
}> = ({ location, onClickMove, onClickEdit, onClickRemove }) => {
  const { sensor } = useSensorLatest(location.unit);

  const amtSnow = SensorService.extractAmtSnow(sensor);
  const temp = SensorService.extractTemp(sensor);
  const createdAtSensor = SensorService.extractCreatedAt(sensor);

  const longitude = LocationService.extractLongitude(location);
  const latitude = LocationService.extractLatitude(location);
  const mapLink = LocationService.extractMapLink(location);
  const snowCoef = LocationService.extractSnowCoef(location);

  return (
    <Table.Tr>
      <Table.Td>
        <Link.Dummy onClick={() => onClickMove(location)}>
          {location.name}
        </Link.Dummy>
      </Table.Td>
      <Table.Td>{amtSnow} cm</Table.Td>
      <Table.Td>{temp} ℃</Table.Td>
      <Table.Td>{createdAtSensor}</Table.Td>
      <Table.Td>
        <Link.Blank className="underline" href={mapLink}>
          Link
        </Link.Blank>
      </Table.Td>
      <Table.Td>{latitude}</Table.Td>
      <Table.Td>{longitude}</Table.Td>
      <Table.Td>{location.unit}</Table.Td>
      <Table.Td>{location.snowBase}</Table.Td>
      <Table.Td>{snowCoef}</Table.Td>
      <Table.Td>
        <div className="flex gap-x-2">
          <div>
            <Button.Normal onClick={() => onClickEdit(location)}>
              編集
            </Button.Normal>
          </div>

          <div>
            <Button.Cancel onClick={() => onClickRemove(location)}>
              削除
            </Button.Cancel>
          </div>
        </div>
      </Table.Td>
    </Table.Tr>
  );
};

/**
 *
 */
export const LocationList = () => {
  const { fetchedLocations: locations } = useAppSelector(
    (state) => state.location
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { deleteLocation } = useDeleteLocation();

  const handleMove = (location: LocationTypes) => {
    dispatch(setSelectedLocation(location));
    dispatch(selectedUnit(location.unit));

    navigate(PAGE.SENSOR);
  };

  const handleEdit = (location: LocationTypes) => {
    dispatch(setEditingLocation(location));
  };

  const handleRemove = async (location: LocationTypes) => {
    const removeResult = await deleteLocation(location.locationId);

    if (removeResult) {
      window.alert("削除しました");
    } else {
      window.alert("削除に失敗しました");
    }
  };

  if (!locations) {
    return (
      <Container.Page>
        <LoadingText />
      </Container.Page>
    );
  }

  return (
    <Container.ScrollX>
      {locations.length === 0 ? (
        <div className="col-span-6">登録されている地点はありません。</div>
      ) : (
        <table>
          <thead>
            <LocationHeaderItem />
          </thead>
          <tbody>
            {locations.map((l) => (
              <LocationItem
                key={l.locationId}
                location={l}
                onClickMove={handleMove}
                onClickEdit={handleEdit}
                onClickRemove={handleRemove}
              />
            ))}
          </tbody>
        </table>
      )}
    </Container.ScrollX>
  );
};
