import { useEffect, useState } from "react";
import { Button } from "../../common/component/Button";
import { Input } from "../../common/component/Input";
import { Text, Title } from "../../common/component/Typography";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import { useCreateLocation } from "../hook/useCreateLocation";
import { useUpdateLocationInfo } from "../hook/useUpdateLocation";
import { setEditingLocation } from "../location.slice";

const LocationForm = () => {
  const [name, setName] = useState<string>("");
  const [unit, setUnit] = useState<string>("");
  const [longitude, setLongitude] = useState<number>();
  const [latitude, setLatitude] = useState<number>();
  const [message, setMessage] = useState<string>("");

  const { editingLocation } = useAppSelector((state) => state.location);
  const dispatch = useAppDispatch();

  const { updateLocationInfo } = useUpdateLocationInfo();
  const { createLocation } = useCreateLocation();

  useEffect(() => {
    if (editingLocation) {
      setName(editingLocation.name);
      setUnit(editingLocation.unit);
      setLatitude(editingLocation.latitude);
      setLongitude(editingLocation.longitude);
    }
  }, [editingLocation]);

  const clearForm = () => {
    setName("");
    setUnit("");
    setLatitude(undefined);
    setLongitude(undefined);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!latitude || !longitude) return;

    if (editingLocation) {
      // 更新
      const updateResult = await updateLocationInfo({
        locationId: editingLocation.locationId,
        name,
        unit,
        latitude,
        longitude,
      });

      if (updateResult) {
        setMessage(`地点名: ${name} を更新しました。`);

        clearForm();
      } else {
        setMessage("更新に失敗しました。");
      }
    } else {
      // 作成
      const createResult = await createLocation({
        name,
        unit,
        latitude,
        longitude,
      });

      if (createResult) {
        setMessage(`地点名: ${name} を作成しました。`);

        clearForm();
      } else {
        setMessage("作成に失敗しました。");
      }
    }

    dispatch(setEditingLocation(null));
  };

  const handleCancel = () => {
    dispatch(setEditingLocation(null));

    clearForm();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input.Wrapper>
        <Input.Label htmlFor="name">地点名</Input.Label>
        <Input.Field
          type="text"
          id="name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Input.Wrapper>

      <Input.Wrapper>
        <Input.Label htmlFor="latitude">経度</Input.Label>
        <Input.Field
          className="w-44"
          type="number"
          id="latitude"
          required
          min={-90}
          max={90}
          step={0.000001}
          value={latitude || ""}
          onChange={(e) => setLatitude(Number(e.target.value))}
        />
        <Input.Supply>小数点以下6桁まで。例) 35.658560</Input.Supply>
      </Input.Wrapper>

      <Input.Wrapper>
        <Input.Label htmlFor="longitude">緯度</Input.Label>
        <Input.Field
          className="w-44"
          type="number"
          id="longitude"
          required
          min={-180}
          max={180}
          step={0.000001}
          value={longitude || ""}
          onChange={(e) => setLongitude(Number(e.target.value))}
        />
        <Input.Supply>小数点以下6桁まで。例) 139.745461</Input.Supply>
      </Input.Wrapper>

      <Input.Wrapper>
        <Input.Label htmlFor="unit">機器ID</Input.Label>
        <Input.Field
          type="number"
          id="unit"
          required
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
        />
        <Input.Supply>
          この値は表示するデータの取得に使用されます。重複した値は登録できません。
        </Input.Supply>
      </Input.Wrapper>

      <Input.Wrapper>
        <div className="flex gap-x-2">
          <Button.Normal type="submit">
            {editingLocation ? "更新" : "追加"}
          </Button.Normal>
          {editingLocation && (
            <div className="ml-2">
              <Button.Trans type="button" onClick={handleCancel}>
                キャンセル
              </Button.Trans>
            </div>
          )}
        </div>
      </Input.Wrapper>

      <Input.Wrapper>
        <Text.Message>{message}</Text.Message>
      </Input.Wrapper>
    </form>
  );
};

export const LocationFormView = () => {
  const { editingLocation } = useAppSelector((state) => state.location);

  return (
    <div>
      <Title.H2>{editingLocation ? "地点の編集" : "地点の追加"}</Title.H2>

      <div>
        <LocationForm />
      </div>
    </div>
  );
};
