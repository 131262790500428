import { useEffect, useState } from "react";
import { axiosX } from "../../lib/axios";
import { SensorChartIntervalTypes, SensorDeviceTypes } from "../sensor.const";
import {
  SensorValuesGetPastTypes,
  SensorValuesPastTypes,
} from "../sensor.type";

export const useSensorValuesPast = (
  unit: string,
  interval: SensorChartIntervalTypes,
  sensorDevice: SensorDeviceTypes,
  take: number
) => {
  const [sensorValues, setSensorValues] =
    useState<SensorValuesPastTypes | null>();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axiosX.get<SensorValuesGetPastTypes>(
          "/sensors/past",
          {
            params: {
              unit: unit,
              interval: interval,
              sensor: sensorDevice,
              take: take,
            },
          }
        );

        if (data && data.data.length > 0 && data.time.length > 0) {
          const fetchedSensorData: SensorValuesPastTypes = {
            time: data.time.map((time) => new Date(time)).reverse(),
            data: data.data.reverse(),
          };

          setSensorValues(fetchedSensorData);
        } else {
          setSensorValues(null);
        }
      } catch (err) {
        console.error(err);

        setSensorValues(null);
      }
    };

    fetch();
  }, [unit, interval, sensorDevice, take]);

  return { sensorValues };
};
