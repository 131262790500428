import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PAGE } from "../../app/app.const";
import {
  CalSensorStepTypes,
  CAL_SENSOR_STEP,
} from "../../cal-sensor/cal-sensor.const";
import { Button } from "../../common/component/Button";
import { Text } from "../../common/component/Typography";
import { SENSOR_STATUS } from "../../sensor/sensor.const";
import { useAppSelector } from "../../store/storeHooks";
import {
  useFinishLocationCalibration,
  useUpdateLocationStatus,
} from "../hook/useUpdateLocation";
import { LOCATION_STATUS } from "../location.const";
import { reloadCalibratingLocation } from "../location.slice";

const MEASURE_BOX_SIZE = 1000;

const useLatestCalSensor = (step: CalSensorStepTypes) => {
  const { fetchedCalSensors } = useAppSelector((state) => state.calSensor);

  const calSensors = fetchedCalSensors.filter(
    (calSensor) =>
      calSensor.step === step &&
      calSensor.amtSnowRaw &&
      calSensor.amtSnowSt === SENSOR_STATUS.OK
  );

  const latestCalSensor = calSensors[0] ?? undefined;

  return { latestCalSensor };
};

/**
 *
 */
export const CalibrationControlView = () => {
  const [isSending, setIsSending] = useState<boolean>(false);

  const { calibratingLocation } = useAppSelector((state) => state.location);

  const { latestCalSensor: latestCalSensorAtBase } = useLatestCalSensor(
    CAL_SENSOR_STEP.BASE
  );
  const { latestCalSensor: latestCalSensorAtTarget } = useLatestCalSensor(
    CAL_SENSOR_STEP.TARGET
  );

  const { updateLocationStatus } = useUpdateLocationStatus();
  const { finishLocationCalibration } = useFinishLocationCalibration();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSendBase = async () => {
    if (
      !calibratingLocation ||
      !latestCalSensorAtBase ||
      !latestCalSensorAtBase.amtSnowRaw
    ) {
      return;
    }
    setIsSending(true);

    await updateLocationStatus(
      calibratingLocation.locationId,
      LOCATION_STATUS.CAL_SETTING_TARGET
    );

    dispatch(reloadCalibratingLocation());

    setIsSending(false);
  };

  const handleSendTarget = async () => {
    if (
      !calibratingLocation ||
      !latestCalSensorAtBase ||
      !latestCalSensorAtTarget ||
      !latestCalSensorAtBase.amtSnowRaw ||
      !latestCalSensorAtTarget.amtSnowRaw
    ) {
      return;
    }
    setIsSending(true);

    const diff =
      latestCalSensorAtBase.amtSnowRaw - latestCalSensorAtTarget.amtSnowRaw;

    // もし、diffが0だったら、snowCoefは1にする。
    const snowCoef = diff === 0 ? 1 : MEASURE_BOX_SIZE / diff;

    const result = await finishLocationCalibration(
      calibratingLocation.locationId,
      latestCalSensorAtBase.amtSnowRaw,
      snowCoef
    );

    navigate(`${PAGE.CALIBRATION}?result=${result}`);
    setIsSending(false);
  };

  // メッセージ
  const message = ((): string => {
    if (!calibratingLocation) return "ロード中...";

    if (isSending) return "送信中...";

    switch (calibratingLocation.status) {
      case LOCATION_STATUS.CAL_SETTING_BASE:
        return "受信ログを確認して、基準値を設定してください。";
      case LOCATION_STATUS.CAL_SETTING_TARGET:
        return "基準値設定OK。受信ログを確認して、校正を実行してください。";

      default:
        navigate(`${PAGE.CALIBRATION}`);
        return "";
    }
  })();

  const isDisabledBase =
    isSending ||
    !latestCalSensorAtBase ||
    !latestCalSensorAtBase.amtSnowRaw ||
    calibratingLocation?.status !== LOCATION_STATUS.CAL_SETTING_BASE;

  const isDisabledTarget =
    isSending ||
    !latestCalSensorAtTarget ||
    !latestCalSensorAtTarget.amtSnowRaw ||
    calibratingLocation?.status !== LOCATION_STATUS.CAL_SETTING_TARGET;

  return (
    <div>
      <div className="flex items-center gap-x-6">
        <div>
          <Button.Normal disabled={isDisabledBase} onClick={handleSendBase}>
            基準値設定
          </Button.Normal>
        </div>
        <div>
          <Button.Normal disabled={isDisabledTarget} onClick={handleSendTarget}>
            校正実行
          </Button.Normal>
        </div>
      </div>

      <div className="mt-4 h-6">
        <Text.Message>{message}</Text.Message>
      </div>
    </div>
  );
};
