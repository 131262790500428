import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import {
  SensorChartIntervalTypes,
  SENSOR_CHART_INTERVAL,
} from "../sensor.const";
import { changeChartInterval } from "../sensor.slice";

export const ChartIntervalSelector = () => {
  const { chartInterval } = useAppSelector((state) => state.sensor);
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newInterval = e.target.value as SensorChartIntervalTypes;

    dispatch(changeChartInterval(newInterval));
  };

  return (
    <div>
      <div>
        <select defaultValue={chartInterval} onChange={handleChange}>
          <option value={SENSOR_CHART_INTERVAL.CONTINUE}>連続</option>
          <option value={SENSOR_CHART_INTERVAL.HOUR}>1時間ごと</option>
          <option value={SENSOR_CHART_INTERVAL.TWO_HOURS}>2時間ごと</option>
          <option value={SENSOR_CHART_INTERVAL.DAY}>1日ごと</option>
          <option value={SENSOR_CHART_INTERVAL.WEEK}>1週間ごと</option>
          <option value={SENSOR_CHART_INTERVAL.TWO_WEEKS}>2週間ごと</option>
          {/* <option value={CHART_INTERVAL.MONTH}>1月ごと</option> */}
        </select>
      </div>
    </div>
  );
};
