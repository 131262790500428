import { ChangeEvent, useState } from "react";
import { useDeleteCalSensors } from "../../cal-sensor/hook/useDeleteCalSensors";
import { Button } from "../../common/component/Button";
import { useAppSelector } from "../../store/storeHooks";
import { useUpdateLocationStatus } from "../hook/useUpdateLocation";
import { LOCATION_STATUS } from "../location.const";
import { LocationTypes } from "../location.type";

export const CalibrationReserveView = () => {
  const [selectLocation, setSelectLocation] = useState<LocationTypes>();
  const [isSending, setIsSending] = useState(false);

  const { fetchedLocations } = useAppSelector((state) => state.location);

  const { updateLocationStatus } = useUpdateLocationStatus();
  const { deleteCalSensors } = useDeleteCalSensors();

  // 有効な観測地点、つまり構成を予約できる一覧を抽出
  const activeLocations = fetchedLocations
    ? fetchedLocations.filter((l) => l.status === LOCATION_STATUS.ACTIVE)
    : [];

  // 観測地点の選択が変更された時
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!e.target.value) {
      return setSelectLocation(undefined);
    }

    const selectedLocationId = Number(e.target.value);
    setSelectLocation(
      activeLocations.find((l) => l.locationId === selectedLocationId)
    );
  };

  // キャリブレーションを予約する
  const handleReserve = async () => {
    if (!selectLocation) return;
    setIsSending(true);

    // キャリブレーションセンサーの削除と観測地点のステータスを更新
    const resultDeleteCalSensor = await deleteCalSensors(selectLocation.unit);
    const resultUpdateLocation = await updateLocationStatus(
      selectLocation.locationId,
      LOCATION_STATUS.CAL_READY
    );

    if (resultDeleteCalSensor && resultUpdateLocation) {
      window.alert("予約しました");
    } else {
      window.alert("予約に失敗しました");
    }

    setSelectLocation(undefined);
    setIsSending(false);
  };

  // 予約ボタンの無効化
  const disabledButton = !selectLocation || isSending;

  return (
    <div>
      <p className="my-2">
        キャリブレーションを実行する観測地点を選択してください。
      </p>

      <div className="my-4">
        <select
          defaultValue={selectLocation?.locationId}
          onChange={handleSelectChange}
        >
          <option value="">選択してください</option>

          {activeLocations.map((l) => (
            <option key={l.locationId} value={l.locationId}>
              {l.name} (機器ID: {l.unit})
            </option>
          ))}
        </select>
      </div>

      <div>
        <Button.Normal disabled={disabledButton} onClick={handleReserve}>
          予約
        </Button.Normal>
      </div>
    </div>
  );
};
