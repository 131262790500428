import { FC, HTMLAttributes, ReactNode } from "react";
import { Link as RLink, NavLinkProps } from "react-router-dom";

/* Link ----------------------------------- */
type LinkProps = NavLinkProps & {
  children: ReactNode;
};

type LinkCurrentProps = Omit<LinkProps, "to">;
type LinkBlankProps = Omit<LinkProps, "to"> & { href: string };

const Page: FC<LinkProps> = ({ className = "", to, children }) => {
  return (
    <RLink
      className={`text-blue-500 hover:text-blue-700 underline ${className}`}
      to={to}
    >
      {children}
    </RLink>
  );
};

const Current: FC<LinkCurrentProps> = ({ className = "", children }) => (
  <span className={`text-zinc-900 ${className}`}>{children}</span>
);

const Dummy: FC<HTMLAttributes<HTMLSpanElement>> = ({
  className = "",
  children,
  ...props
}) => (
  <span
    className={`text-blue-500 hover:text-blue-700 underline cursor-pointer ${className}`}
    {...props}
  >
    {children}
  </span>
);

const Blank: FC<LinkBlankProps> = ({ className = "", href, children }) => {
  return (
    <a
      className={`text-blue-500 hover:text-blue-700 underline ${className}`}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export const Link = { Page, Current, Dummy, Blank };
