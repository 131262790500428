import { Button } from "../../common/component/Button";
import { axiosX } from "../../lib/axios";
import { downloadLocationCsv } from "../../lib/csv";
import { LocationGetIndexTypes } from "../location.type";

export const LocationCsvOutput = () => {
  const handleClick = async () => {
    try {
      const { data: locations } = await axiosX.get<LocationGetIndexTypes[]>(
        "/locations"
      );

      downloadLocationCsv(locations);
    } catch (err) {
      console.error(err);

      alert("エラー: CSV出力に失敗しました。");
    }
  };

  return (
    <div>
      <Button.Normal onClick={handleClick}>出力</Button.Normal>
    </div>
  );
};
