import { useEffect } from "react";
import { axiosX } from "../../lib/axios";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import { setFetchedLocations } from "../location.slice";
import { LocationGetIndexTypes, LocationTypes } from "../location.type";

export const useFetchAndStoreLocations = () => {
  const { fetchReloader } = useAppSelector((state) => state.location);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axiosX.get<LocationGetIndexTypes[]>(
          "/locations"
        );

        const fetchedLocations: LocationTypes[] = data.map((location) => {
          return {
            ...location,
          };
        });

        dispatch(setFetchedLocations(fetchedLocations));
      } catch (err) {
        console.error(err);

        dispatch(setFetchedLocations([]));
      }
    };

    fetch();
  }, [fetchReloader, dispatch]);
};
