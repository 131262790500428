import { formatF } from "../lib/date";
import {
  SensorChartIntervalTypes,
  SENSOR_CHART_INTERVAL,
} from "./sensor.const";

export class SensorHelper {
  public static formatTimeLabels(
    times: Date[],
    chartInterval: SensorChartIntervalTypes
  ) {
    return times.map((time) => {
      switch (chartInterval) {
        case SENSOR_CHART_INTERVAL.CONTINUE:
          return formatF(time, "yyyy/M/d HH:mm");
        case SENSOR_CHART_INTERVAL.HOUR:
        case SENSOR_CHART_INTERVAL.TWO_HOURS:
          return formatF(time, "d日 HH:mm");
        case SENSOR_CHART_INTERVAL.DAY:
          return formatF(time, "M/d");
        case SENSOR_CHART_INTERVAL.WEEK:
          return formatF(time, "M/d〜");
        case SENSOR_CHART_INTERVAL.TWO_WEEKS:
          return formatF(time, "M/d〜");
        case SENSOR_CHART_INTERVAL.MONTH:
          return formatF(time, "yyyy年M月");
        default:
          return time;
      }
    });
  }
}
