import { format } from "date-fns";
import { LocationGetIndexTypes } from "../location/location.type";
import { SensorGetCsvDataTypes } from "../sensor/sensor.type";
import { formatF } from "./date";

const formatName = (date: string): string => {
  return format(new Date(date), "yyyyMMdd");
};

const createSensorCsv = (data: SensorGetCsvDataTypes[]) => {
  const dataHeader =
    [
      "ID",
      "日時",
      "機器ID",
      "積雪深",
      "積雪深ステータス",
      "気温",
      "気温ステータス",
    ].join(",") + "\n";

  const dataBody = data.map((d) => {
    return (
      [
        d.sensorId,
        formatF(new Date(d.createdAtJst), "yyyy/MM/dd HH:mm:ss"),
        d.unit,
        d.amtSnow,
        d.amtSnowSt,
        d.temp,
        d.tempSt,
      ].join(",") + "\n"
    );
  });

  return [dataHeader, ...dataBody];
};

const createLocationCsv = (data: LocationGetIndexTypes[]) => {
  const dataHeader =
    ["ID", "機器ID", "地点名", "経度", "緯度", "基準値", "係数"].join(",") +
    "\n";

  const dataBody = data.map((d) => {
    return (
      [
        d.locationId,
        d.unit,
        d.name,
        d.latitude,
        d.longitude,
        d.snowBase,
        d.snowCoef,
      ].join(",") + "\n"
    );
  });

  return [dataHeader, ...dataBody];
};

const downloadCsv = (data: string[], docName: string) => {
  const blob = new Blob(data, { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", docName);
  link.click();
};

export const downloadSensorCsv = (
  data: SensorGetCsvDataTypes[],
  from: string,
  to: string
) => {
  const dataCsv = createSensorCsv(data);

  const docName = `sensor-data-${formatName(from)}-${formatName(to)}.csv`;

  downloadCsv(dataCsv, docName);
};

export const downloadLocationCsv = (data: LocationGetIndexTypes[]) => {
  const dataCsv = createLocationCsv(data);

  const docName = `location-data-${formatName(new Date().toISOString())}.csv`;

  downloadCsv(dataCsv, docName);
};
