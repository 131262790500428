import { FC, HTMLAttributes, TdHTMLAttributes } from "react";

const Tr: FC<HTMLAttributes<HTMLTableRowElement>> = ({
  children,
  className = "",
  ...props
}) => (
  <tr className={`border-b border-slate-300 ${className}`} {...props}>
    {children}
  </tr>
);

const Td: FC<TdHTMLAttributes<HTMLTableCellElement>> = ({
  children,
  className = "",
  ...props
}) => (
  <td className={`px-5 py-2 whitespace-nowrap ${className}`} {...props}>
    {children}
  </td>
);

export const Table = {
  Tr,
  Td,
};
