// TODO:削除予定
export const LOCATION_FORM_MODE = {
  ADD: "add",
  EDIT: "edit",
} as const;

export type LocationFormModeTypes =
  typeof LOCATION_FORM_MODE[keyof typeof LOCATION_FORM_MODE];

export const LOCATION_STATUS = {
  ACTIVE: 1,
  CAL_READY: 2,
  CAL_SETTING_BASE: 11,
  CAL_SETTING_TARGET: 12,
} as const;

export type LocationStatusTypes =
  typeof LOCATION_STATUS[keyof typeof LOCATION_STATUS];
