import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocationTypes } from "./location.type";

type LocationState = {
  fetchReloader: number;
  fetchedLocations: LocationTypes[] | undefined;
  selectedLocation: LocationTypes | null;
  editingLocation: LocationTypes | null;
  reloaderCalibratingLocation: number;
  calibratingLocation: LocationTypes | null;
};

const initialState: LocationState = {
  fetchReloader: 1,
  fetchedLocations: undefined,
  selectedLocation: null,
  editingLocation: null,
  reloaderCalibratingLocation: 1,
  calibratingLocation: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    reloadFetchedLocations: (state) => {
      state.fetchReloader++;
    },
    setFetchedLocations: (state, action: PayloadAction<LocationTypes[]>) => {
      state.fetchedLocations = action.payload;
    },
    setSelectedLocation: (
      state,
      action: PayloadAction<LocationTypes | null>
    ) => {
      state.selectedLocation = action.payload;
    },
    setEditingLocation: (
      state,
      action: PayloadAction<LocationTypes | null>
    ) => {
      state.editingLocation = action.payload;
    },
    reloadCalibratingLocation: (state) => {
      state.reloaderCalibratingLocation++;
    },
    setCalibratingLocation: (
      state,
      action: PayloadAction<LocationTypes | null>
    ) => {
      state.calibratingLocation = action.payload;
    },
  },
});

export const locationReducer = locationSlice.reducer;
export const {
  reloadFetchedLocations,
  setFetchedLocations,
  setSelectedLocation,
  setEditingLocation,
  reloadCalibratingLocation,
  setCalibratingLocation,
} = locationSlice.actions;
