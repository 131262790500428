import { format as formatDateFns, subHours } from "date-fns";

/**
 * タイムゾーンを無視して、そのまま日本時間としてフォーマットする。
 * DateFnsのformatを通すと、タイムゾーンが考慮されてしまうため。
 */
export const formatF = (time: Date, format: string): string => {
  const fixedTime = subHours(time, 9);
  return formatDateFns(fixedTime, format);
};

export const fixTimeDiffUtc = (time: Date): Date => {
  return subHours(time, 9);
};
