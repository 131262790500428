import { useEffect, useState } from "react";
import { axiosX } from "../lib/axios";
import { AuthTypes } from "./auth.type";

/**
 * Auth Hook
 */
export const useAuth = () => {
  const [currentAuth, setCurrentAuth] = useState<AuthTypes | null>();
  const [updater, setUpdater] = useState(0);

  // update current auth
  const update = () => {
    setUpdater(updater + 1);
  };

  // Logout
  const logout = async () => {
    try {
      await axiosX.post("/auth/logout");

      setCurrentAuth(null);

      return true;
    } catch (err) {
      console.log(err);

      return false;
    }
  };

  // Login
  const login = async (
    loginId: string,
    loginPass: string
  ): Promise<boolean> => {
    try {
      const postData = {
        loginId,
        loginPass,
      };

      await axiosX.post("/auth/login", postData);
      update();

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  };

  // get current
  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await axiosX.get<AuthTypes>("/auth/verify");

        if (data) {
          const auth: AuthTypes = {
            userId: data.userId,
            loginId: data.loginId,
          };

          setCurrentAuth(auth);
        } else {
          setCurrentAuth(null);
        }
      } catch (err) {
        setCurrentAuth(null);
      }
    };

    getUser();
  }, [updater]);

  return { currentAuth, update, logout, login };
};
