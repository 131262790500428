import { FC, ReactNode } from "react";

/* Title ----------------------------------- */
type TitleProps = {
  className?: string;
  children?: ReactNode;
};

const H1: FC<TitleProps> = ({ children, className }) => {
  return <h1 className={`text-3xl ${className}`}>{children}</h1>;
};

const H2: FC<TitleProps> = ({ children, className }) => {
  return <h2 className={`text-2xl ${className}`}>{children}</h2>;
};

const H3: FC<TitleProps> = ({ children, className }) => {
  return <h3 className={`text-xl ${className}`}>{children}</h3>;
};

const Sub: FC<TitleProps> = ({ children, className }) => (
  <p className={`mt-2 text-xl ${className}`}>{children}</p>
);

export const Title = { H1, H2, H3, Sub };

/* Text ----------------------------------- */
type TextProps = {
  className?: string;
  children?: ReactNode;
};

const Message: FC<TextProps> = ({ children, className }) => (
  <p className={`text-red-600 ${className}`}>{children}</p>
);

export const Text = { Message };
