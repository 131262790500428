import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/storeHooks";
import { SENSOR_DEVICE } from "../sensor.const";
import { AmtSnowChart } from "./charts/AmtSnowChart";
import { TempChart } from "./charts/TempChart";

const ASPECT = 0.625;

export const PastSensorView = () => {
  const { sensorDevice } = useAppSelector((state) => state.sensor);
  const [minHeight, setMinHeight] = useState(window.innerHeight);
  const wrapperRef = useRef<HTMLDivElement>(null);

  let chart = null;

  switch (sensorDevice) {
    default:
    case SENSOR_DEVICE.AMT_SNOW:
      chart = <AmtSnowChart />;
      break;
    case SENSOR_DEVICE.TEMP:
      chart = <TempChart />;
      break;
  }

  useEffect(() => {
    if (wrapperRef.current) {
      const minHeight = wrapperRef.current.clientWidth * ASPECT + 16;

      setMinHeight(minHeight);
    }
  }, [wrapperRef]);

  if (!chart) return null;

  return (
    <div ref={wrapperRef} style={{ minHeight: `${minHeight}px` }}>
      {chart}
    </div>
  );
};
