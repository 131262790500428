import { format, subDays } from "date-fns";
import { ChangeEvent, FormEvent, useState } from "react";
import { Button } from "../../common/component/Button";
import { Input } from "../../common/component/Input";
import { axiosX } from "../../lib/axios";
import { downloadSensorCsv } from "../../lib/csv";
import { fixTimeDiffUtc } from "../../lib/date";
import { SensorGetCsvDataTypes } from "../sensor.type";

const formatDate = (date: Date): string => {
  return format(new Date(date), "yyyy-MM-dd");
};

export const SensorCsvOutput = () => {
  const [from, setFrom] = useState<string>(formatDate(subDays(new Date(), 8)));
  const [to, setTo] = useState<string>(formatDate(subDays(new Date(), 1)));

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;

    switch (id) {
      case "from":
        setFrom(value);
        break;
      case "to":
        setTo(value);
        break;
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const { data: sensors } = await axiosX.get<SensorGetCsvDataTypes[]>(
        "/sensors/csv",
        {
          params: {
            from: fixTimeDiffUtc(new Date(from)),
            to: fixTimeDiffUtc(new Date(to)),
          },
        }
      );

      downloadSensorCsv(sensors, from, to);
    } catch (err) {
      console.error(err);

      alert("エラー: CSV出力に失敗しました。");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input.Wrapper>
        <Input.Label>期間</Input.Label>

        <div className="flex items-center gap-x-2">
          <Input.Field
            id="from"
            type="date"
            required
            value={from}
            onChange={handleDateChange}
          />
          <div>〜</div>
          <Input.Field
            id="to"
            type="date"
            required
            value={to}
            onChange={handleDateChange}
          />
        </div>
      </Input.Wrapper>

      <Input.Wrapper>
        <Button.Normal type="submit">出力</Button.Normal>
      </Input.Wrapper>
    </form>
  );
};
