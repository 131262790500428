import { FC, ReactNode } from "react";
import { To, useLocation } from "react-router-dom";
import { PAGE } from "../../app/app.const";
import { Link } from "./Link";

const Item: FC<{ to: To; children: ReactNode }> = ({ to, children }) => {
  const { pathname } = useLocation();

  const current = pathname === to || (to === PAGE.HOME && pathname === "/");

  return (
    <li>
      {current ? (
        <Link.Current>{children}</Link.Current>
      ) : (
        <Link.Page to={to}>{children}</Link.Page>
      )}
    </li>
  );
};

export const MenuView = () => {
  return (
    <ul className="flex flex-wrap gap-x-3 gap-y-2">
      <Item to={PAGE.LOCATION}>一覧</Item> |
      <Item to={PAGE.CALIBRATION}>キャリブレーション</Item> |
      <Item to={PAGE.HOME}>その他</Item>
    </ul>
  );
};
