import { configureStore } from "@reduxjs/toolkit";
import { calSensorReducer } from "../cal-sensor/cal-sensor.slice";
import { locationReducer } from "../location/location.slice";
import { sensorReducer } from "../sensor/sensor.slice";

export const store = configureStore({
  reducer: {
    sensor: sensorReducer,
    location: locationReducer,
    calSensor: calSensorReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
