import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  SensorChartIntervalTypes,
  SensorDeviceTypes,
  SENSOR_CHART_INTERVAL,
  SENSOR_DEVICE,
} from "../sensor/sensor.const";

type SensorState = {
  selectedUnit: string;
  sensorDevice: SensorDeviceTypes;
  chartInterval: SensorChartIntervalTypes;
};

const initialState: SensorState = {
  selectedUnit: "1",
  sensorDevice: SENSOR_DEVICE.AMT_SNOW,
  chartInterval: SENSOR_CHART_INTERVAL.HOUR,
} as const;

const sensorSlice = createSlice({
  name: "sensor",
  initialState: initialState,
  reducers: {
    selectedUnit: (state, action: PayloadAction<string>) => {
      // clear
      state.sensorDevice = initialState.sensorDevice;
      state.chartInterval = initialState.chartInterval;

      // set unit
      state.selectedUnit = action.payload;
    },
    changeSensorDevice: (state, action: PayloadAction<SensorDeviceTypes>) => {
      state.sensorDevice = action.payload;
    },
    changeChartInterval: (
      state,
      action: PayloadAction<SensorChartIntervalTypes>
    ) => {
      state.chartInterval = action.payload;
    },
  },
});

export const sensorReducer = sensorSlice.reducer;
export const { selectedUnit, changeSensorDevice, changeChartInterval } =
  sensorSlice.actions;
