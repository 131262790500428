import { useContext } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { Button } from "../../common/component/Button";

export const UserInfo = () => {
  const { currentAuth, logout } = useContext(AuthContext);

  const handleClick = async () => {
    await logout();
  };

  return (
    <div>
      <p>ユーザー名: {currentAuth?.loginId}</p>

      <div className="mt-4">
        <Button.Normal type="button" onClick={handleClick}>
          ログアウト
        </Button.Normal>
      </div>
    </div>
  );
};
