import { Container } from "../common/component/Container";
import { MenuView } from "../common/component/MenuView";
import { Title } from "../common/component/Typography";
import { LocationFormView } from "../location/component/LocationFormView";
import { LocationList } from "../location/component/LocationList";
import { useFetchAndStoreLocations } from "../location/hook/useFetchAndStoreLocation";

export const LocationPage = () => {
  useFetchAndStoreLocations();

  return (
    <Container.Page>
      <Title.H1>積雪深センサービューワー</Title.H1>

      <div className="my-4">
        <MenuView />
      </div>

      <Container.Section>
        <Title.H2>観測地点 一覧</Title.H2>
        <p className="my-2">地点名を押すと、詳細情報を見ることができます。</p>
        <p className="text-sm">
          ※ "基準値"
          "係数"は、積雪深を測定する際に使われ、キャリブレーションで設定できます。
        </p>

        <div className="my-4 max-w-full">
          <LocationList />
        </div>
      </Container.Section>

      <Container.Section>
        <LocationFormView />
      </Container.Section>
    </Container.Page>
  );
};
