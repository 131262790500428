import { FC, InputHTMLAttributes, LabelHTMLAttributes, ReactNode } from "react";

const Wrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="my-4">{children}</div>
);

const Label: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  className = "",
  ...props
}) => (
  <label className={`block max-w-fit ${className}`} {...props}>
    {children}
  </label>
);

const Field: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  children,
  className = "",
  ...props
}) => <input className={`block ${className}`} {...props} />;

const Supply: FC<{ children: ReactNode }> = ({ children }) => (
  <p className="my-1 text-sm">{children}</p>
);

export const Input = {
  Wrapper,
  Label,
  Field,
  Supply,
};
