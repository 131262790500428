import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PAGE } from "../app/app.const";
import { reloadFetchedCalSensor } from "../cal-sensor/cal-sensor.slice";
import { CalSensorLogs } from "../cal-sensor/component/CalSensorLogs";
import { useFetchAndStoreCalSensor } from "../cal-sensor/hook/useFetchAndStoreCalSensor";
import { Container } from "../common/component/Container";
import { Link } from "../common/component/Link";
import { Title } from "../common/component/Typography";
import { CalibrationControlView } from "../location/component/CalibrationControlView";
import { useFetchAndStoreCalibratingLocation } from "../location/hook/useFetchedAndStoreCalibratingLocation";
import { reloadCalibratingLocation } from "../location/location.slice";
import { useAppSelector } from "../store/storeHooks";

const UPDATE_INTERVAL = 5000;

/**
 * TODO: データが一つもない時に基準値設定と構成を実行できないように
 */
export const CalibrationSelectedPage = () => {
  const { id } = useParams();

  const { calibratingLocation } = useAppSelector((state) => state.location);

  useFetchAndStoreCalibratingLocation(Number(id));
  useFetchAndStoreCalSensor(calibratingLocation);

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(reloadCalibratingLocation());
      dispatch(reloadFetchedCalSensor());
    }, UPDATE_INTERVAL);

    return () => clearInterval(timer);
  }, [dispatch]);

  return (
    <Container.Page>
      <Title.H1>積雪深センサービューワー</Title.H1>
      <Title.Sub>キャリブレーション中</Title.Sub>

      <p className="my-4">
        受信ログの値を参考に、まず基準値を設定します。
        <br />
        その後、校正用の箱を設置し、適切な値を確認できたら、校正実行ボタンを押してください。
      </p>

      <div>
        <Link.Page className="text-base" to={`${PAGE.CALIBRATION}`}>
          戻る
        </Link.Page>
      </div>

      <Container.Section>
        <Title.H2>
          校正ターゲット:{" "}
          <span className="font-bold">
            {calibratingLocation?.name}（機器ID: {calibratingLocation?.unit}）
          </span>
        </Title.H2>

        <div className="my-4">
          <CalibrationControlView />
        </div>
      </Container.Section>

      <Container.Section>
        <Title.H3 className="mt-8">受信ログ</Title.H3>

        <CalSensorLogs />
      </Container.Section>
    </Container.Page>
  );
};
