import { FC, ReactNode, useEffect, useRef, useState } from "react";

type ContainerProps = {
  children?: ReactNode;
};

const Page: FC<ContainerProps> = ({ children }) => {
  return <div className="mx-auto py-4 w-11/12 max-w-screen-lg">{children}</div>;
};

const Section: FC<ContainerProps> = ({ children }) => {
  return <div className="my-12">{children}</div>;
};

const ScrollX: FC<ContainerProps> = ({ children }) => {
  const [gap, setGap] = useState<number>(0);
  const divRef = useRef<HTMLDivElement>(null);
  const docWidth = useRef<number>(1000);

  // TODO: リサイズ時にも対応する
  useEffect(() => {
    const fixGap = () => {
      if (divRef.current && docWidth.current !== document.body.clientWidth) {
        docWidth.current = document.body.clientWidth;
        const divWidth = divRef.current.clientWidth;

        setGap((docWidth.current - divWidth) / 2);
      }
    };

    fixGap();

    window.addEventListener("resize", fixGap);

    return () => {
      window.removeEventListener("resize", fixGap);
    };
  }, [divRef]);

  const divStyle = {
    width: docWidth.current,
    marginLeft: -gap,
    paddingLeft: gap,
  };

  return (
    <div ref={divRef}>
      <div className="pb-4 overflow-x-auto" style={divStyle}>
        {children}
      </div>
    </div>
  );
};

export const Container = { Page, Section, ScrollX };
