import { FC } from "react";
import { formatF } from "../../lib/date";
import { SENSOR_STATUS } from "../../sensor/sensor.const";
import { useAppSelector } from "../../store/storeHooks";
import { CAL_SENSOR_STEP } from "../cal-sensor.const";
import { CalSensorTypes } from "../cal-sensor.type";

const LogItem: FC<{ calSensor: CalSensorTypes }> = ({ calSensor }) => {
  const step = (() => {
    switch (calSensor.step) {
      case CAL_SENSOR_STEP.BASE:
        return "基準値設定中";
      case CAL_SENSOR_STEP.TARGET:
        return "校正実行前";
    }
  })();

  const amtSnow =
    calSensor.amtSnowSt === SENSOR_STATUS.OK
      ? calSensor.amtSnowRaw
      : calSensor.amtSnowSt;

  return (
    <li className="text-sm">
      <time>{formatF(new Date(calSensor.createdAtJst), "MM/dd HH:mm:ss")}</time>
      <span className="inline-block ml-4">{step}</span>
      <span className="inline-block ml-4">データ値: {amtSnow} mm</span>
    </li>
  );
};

export const CalSensorLogs = () => {
  const { fetchedCalSensors } = useAppSelector((state) => state.calSensor);

  return (
    <ul className="my-2 h-96 overflow-y-auto">
      {fetchedCalSensors.map((log, index) => (
        <LogItem key={index} calSensor={log} />
      ))}
    </ul>
  );
};
