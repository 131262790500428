import Axios from "axios";
import { REACT_APP_BACKEND_URL } from "../app/app.env";

const baseUrl = `${REACT_APP_BACKEND_URL}/api/v1`;

export const axiosX = Axios.create({
  baseURL: baseUrl,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  withCredentials: true,
});
