import { axiosX } from "../../lib/axios";

export const useDeleteCalSensors = () => {
  const deleteCalSensors = async (unit: string) => {
    try {
      await axiosX.delete(`/cal-sensors`, {
        params: {
          unit: unit,
        },
      });

      return true;
    } catch (err) {
      console.error(err);

      return false;
    }
  };

  return { deleteCalSensors };
};
