import { useEffect } from "react";
import { axiosX } from "../../lib/axios";
import { LocationTypes } from "../../location/location.type";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import { setFetchedCalSensor } from "../cal-sensor.slice";
import { CalSensorGetIndexTypes, CalSensorTypes } from "../cal-sensor.type";

/**
 *
 */
export const useFetchAndStoreCalSensor = (
  location: LocationTypes | undefined | null
) => {
  const { fetchReloader } = useAppSelector((state) => state.calSensor);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!location) return;

    const fetch = async () => {
      try {
        const { data } = await axiosX.get<CalSensorGetIndexTypes[]>(
          "/cal-sensors",
          {
            params: {
              unit: location.unit,
            },
          }
        );

        const fetchedLocations: CalSensorTypes[] = data.map((location) => {
          return {
            ...location,
          };
        });

        dispatch(setFetchedCalSensor(fetchedLocations));
      } catch (err) {
        console.error(err);

        dispatch(setFetchedCalSensor([]));
      }
    };

    fetch();
  }, [dispatch, location, fetchReloader]);
};
