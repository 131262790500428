import { useEffect } from "react";
import { axiosX } from "../../lib/axios";
import { useAppDispatch, useAppSelector } from "../../store/storeHooks";
import { setCalibratingLocation } from "../location.slice";
import { LocationGetShowTypes, LocationTypes } from "../location.type";

export const useFetchAndStoreCalibratingLocation = (locationId: number) => {
  const { reloaderCalibratingLocation } = useAppSelector(
    (state) => state.location
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axiosX.get<LocationGetShowTypes>(
          `/location/${locationId}`
        );

        const location: LocationTypes = {
          ...data,
        };

        dispatch(setCalibratingLocation(location));
      } catch (err) {
        console.error(err);

        dispatch(setCalibratingLocation(null));
      }
    };

    fetch();
  }, [reloaderCalibratingLocation, locationId, dispatch]);
};
