import { Line } from "react-chartjs-2";
import { LoadingText, NoDataText } from "../../../common/component/Other";
import { useAppSelector } from "../../../store/storeHooks";
import { useSensorValuesPast } from "../../hook/useSensorValuesPast";
import { SENSOR_DEVICE } from "../../sensor.const";
import { SensorHelper } from "../../sensor.hepler";

const dataLabel = "気温";
const color = "#ff9900";

export const TempChart = () => {
  const { selectedUnit, chartInterval } = useAppSelector(
    (state) => state.sensor
  );
  const { sensorValues } = useSensorValuesPast(
    selectedUnit,
    chartInterval,
    SENSOR_DEVICE.TEMP,
    12
  );

  if (sensorValues === undefined) return <LoadingText />;
  if (sensorValues === null) return <NoDataText />;

  const labels = SensorHelper.formatTimeLabels(
    sensorValues.time,
    chartInterval
  );

  const values = sensorValues.data.map((value) => {
    return value === null ? null : value.toFixed(1);
  });

  return (
    <div>
      <Line
        options={{
          responsive: true,
          scales: {
            y: {
              title: {
                display: true,
                text: "℃",
              },
            },
          },
          plugins: {
            legend: {
              display: true,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: dataLabel,
              data: values,
              backgroundColor: color,
              borderColor: color,
            },
          ],
        }}
      />
    </div>
  );
};
